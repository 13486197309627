<template>
  <b-card>
    <concession-form />
  </b-card>
</template>

<script>

import ConcessionForm from "@/components/ConcessionForm";

export default {
  name: "ConcessionAdd",
  components: {ConcessionForm}
}
</script>

<style scoped>

</style>